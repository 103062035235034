import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import DotLoader from 'react-spinners/DotLoader';
import { format } from 'date-fns';
import './../styles.css';

import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/sv';
import 'dayjs/locale/zh-cn';

const useStyles = makeStyles({
  '@global': {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline .select-idioma': {
      border: 0
    },
    '.MuiMenu-list': {
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '0'
    },
    '.MuiMenu-list :hover': {
      backgroundColor: '#D3D3D3 !important'
    },
    '.MuiTextField-root': {
      width: '100%'
    }
  }
});

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' target={'_blank'} href='https://journeytok.com/'>
        Journeytok
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Landing(props) {
  const { i18n, t } = useTranslation();
  const [logo, setLogo] = React.useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState('#ffbd21');
  const [hotelName, setHotelName] = React.useState('');
  const [language, setLanguage] = React.useState(i18n.language ?? 'es');
  const [arrivalDate, setArrivalDate] = React.useState('');
  const [errorArrivalDate, setErrorArrivalDate] = React.useState(false);
  const [idReserva, setIdReserva] = React.useState('');
  const [mostrarError, setMostrarError] = React.useState(false);
  const { changeLanguage } = useI18next();
  const isBrowser = () => typeof window !== "undefined"
  const [loading, setLoading] = React.useState(true);
  const [errorLocal, setErrorLocal] = React.useState(false);
  const activeLanguage = isBrowser() ? window.localStorage.getItem('gatsby-i18next-language') : 'es';
  const [localesId, setLocalesId] = React.useState([]);
  const [local, setLocal] = React.useState('');

  const countryList = [
    { value: 'es', language: 'Español' },
    { value: 'en', language: 'English' },
    { value: 'de', language: 'Deutsche' },
    { value: 'it', language: 'Italiano' },
    { value: 'fr', language: 'Français' },
    { value: 'ca', language: 'Catalá' },
    { value: 'ja', language: '日本語' },
    { value: 'nl', language: 'Nederlands' },
    { value: 'pt', language: 'Português' },
    { value: 'ru', language: 'Русский' },
    { value: 'sv', language: 'Svenska' },
    { value: 'zh', language: '中文' }
  ];

  const id = new URLSearchParams(props.location.search).get('id');
  const idDiv = new URLSearchParams(props.location.search).get('div');

  useStyles();

  const theme = createTheme();

  React.useEffect(() => {
    changeLanguage(language);
  }, [language]);

  const handleChangeLanguage = (event) => {
    setLanguage(event);
  };

  const getLocal = async () => {
    if (id) {
      try {
        const result = await axios.get(`/apiCheckin/local?id=${id}`);
        const jsonData = result.data;
        const { error, mensaje_error } = result.data;
        if (error) {
          if (mensaje_error) {
            throw mensaje_error;
          } else {
            throw new Error('ERROR');
          }
        }
        setHotelName(jsonData.hotelName);
        if (jsonData.backgroundColor) {
          setBackgroundColor(jsonData.backgroundColor);
        }
        if (jsonData.logo) {
          setLogo(jsonData.logo);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setErrorLocal(true);
      }
    } else {
      try {
        const result = await axios.get(`/apiCheckin/division?id=${idDiv}`);
        const jsonData = result.data;
        const { error, mensaje_error } = result.data;
        if (error) {
          if (mensaje_error) {
            throw mensaje_error;
          } else {
            throw new Error('ERROR');
          }
        }
        setHotelName(jsonData.hotelName);
        if (jsonData.backgroundColor) {
          setBackgroundColor(jsonData.backgroundColor);
        }
        if (jsonData.logo) {
          setLogo(jsonData.logo);
        }
        setLoading(false);
        setLocalesId(jsonData.locales);
      } catch (e) {
        setLoading(false);
        setErrorLocal(true);
      }
    }
  };

  React.useEffect(() => {
    getLocal();
  }, []);


  const buscaCheckin = async () => {
    try {
      const response = await axios.post('/apiCheckin/localizar', { id, idRes: idReserva, fecha: format(arrivalDate, 'yyyy-MM-dd'), div: idDiv, local });
      const datos = response.data;
      if (isBrowser() && datos.encontrada) {
        window.location.href = datos.link;
      } else {
        setMostrarError(true);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading && (
        <Container
          component='main'
          maxWidth='sm'
          sx={{ mb: 6 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '40vh'
          }}
        >
          <DotLoader
            color='#556EE6'
            loading={loading}
            size={150}
            aria-label='Loading booking info'
            data-testid='booking info'
          />
        </Container>
      )}
      {!loading && !errorLocal && (
        <>
          <AppBar
            position='absolute'
            color='default'
            elevation={0}
            sx={{
              position: 'relative',
              borderBottom: (t) => `1px solid ${t.palette.divider}`
            }}
          >
            <Toolbar style={{ backgroundColor: backgroundColor }}>
              <Grid
                container
                direction='row'
                sx={{ display: 'flex', justifyContent: 'center' }}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item xs='auto'>
                  <div>
                    {logo && (
                      <img
                        height={38}
                        width="auto"
                        alt={'logo'}
                        src={`https://${process.env.GATSBY_LOGO_DOMAIN}/app/Assets/img/logos/${logo}`}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs='auto'>
                  <Typography
                    style={{ marginLeft: '1vh', marginRight: '1vh' }}
                    variant='subtitle1'
                    color='inherit'
                    noWrap
                  >
                    {hotelName}
                  </Typography>
                </Grid>
                <Grid item xs='auto'>
                  <FormControl>
                    <Select
                      IconComponent={null}
                      className='select-idioma'
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 }
                      }}
                      style={{
                        background: 'transparent',
                        border: 'none'
                      }}
                      id='select-idioma'
                      renderValue={() => (
                        <i
                          style={{ fontSize: '20px', cursor: 'pointer' }}
                          className='bx bx-globe'
                        />
                      )}
                      defaultValue={language}
                      label='idioma'
                      onChange={(event) => {
                        handleChangeLanguage(event.target.value);
                      }}
                    >
                      {countryList.map((flag) => (
                        <MenuItem
                          style={{
                            listStyle: 'none',
                            paddingLeft: '2vh',
                            paddingRight: '2vh',
                            backgroundColor: 'rgba(240,248,255, 0.95)'
                          }}
                          value={flag.value}
                          key={flag.value}
                        >
                          {flag.language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Container component='main' maxWidth='sm' sx={{ mb: 6 }}>
            <Paper
              style={{
                borderRadius: '3px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px',
                boxSizing: 'border-box'
              }}
              elevation={0}
              sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
            >
              <Grid>
                <Typography variant='h6' gutterBottom align='center'>
                  {t('Check-in online')}
                </Typography>
                <Typography gutterBottom paragraph align='center'>
                  {t('Agilizar entrada')}
                  <br />
                  <br />
                  {t('Introduzca datos')}
                </Typography>
              </Grid>
              <Grid>
                {idDiv &&(
                  <Select
                    style={{ width: '100%', marginBottom: '5%' }}
                    displayEmpty={t('Choose establishment')}
                    value={local}
                    renderValue={(v) => {
                      if (v === '') return t('Choose establishment');
                      else return v;
                    }}
                    onChange={(event) => setLocal(event.target.value)}
                  >
                    {localesId.map(local => (
                      <MenuItem
                        style={{
                          listStyle: 'none',
                          paddingLeft: '2vh',
                          paddingRight: '2vh',
                        }}
                        value={local}
                        key={local}
                      >
                        {local}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <TextField
                  required
                  value={idReserva}
                  id='idReserva'
                  type='idReserva'
                  name='idReserva'
                  label={t('Booking ID')}
                  fullWidth
                  onChange={(newValue) => {
                    setIdReserva(newValue.target.value);
                    setMostrarError(false);
                  }}
                  variant='outlined'
                  style={{ marginBottom: '5%' }}
                />
                <LocalizationProvider
                  adapterLocale={activeLanguage}
                  dateAdapter={AdapterDayjs}
                >
                  <DesktopDatePicker
                    label={t('Arrival date')}
                    inputFormat='dd/MM/yyyy'
                    style={{ width: '100% !important' }}
                    minDate={dayjs(new Date())}
                    value={dayjs(arrivalDate)}
                    onChange={(newValue) => {
                      if (newValue) {
                        setArrivalDate(newValue.toDate());
                        setMostrarError(false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={errorArrivalDate}
                        helperText={errorArrivalDate ? t('Enter a valid date') : ''}
                      />
                    )}
                  />
                </LocalizationProvider>
                {mostrarError && (
                  <Grid direction='row' sx={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                    <span style={{ color: 'red' }}>
                      {t('Error, reservation not found')}
                    </span>
                  </Grid>
                )}
                <Grid direction='row' sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant='contained'
                    onClick={buscaCheckin}
                    color='primary'
                    style={{ marginTop: '5%' }}
                    disabled={
                      !idReserva ||
                      !arrivalDate ||
                      errorArrivalDate ||
                      (idDiv && local === '')
                    }
                  >
                    {t('Search')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Copyright />
          </Container>
        </>
      )}
      {!loading && errorLocal && (
        <Container
          component='main'
          maxWidth='sm'
          sx={{ mb: 6 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '40vh'
          }}
        >
          <Paper
            style={{
              borderRadius: '3px',
              boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px',
              boxSizing: 'border-box'
            }}
            elevation={0}
            sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
          >
            <Grid>
              <Typography gutterBottom paragraph align='center'>
                {t('URL landing no valida')}
              </Typography>
            </Grid>
          </Paper>
        </Container>
      )}
    </ThemeProvider>
  )
};
