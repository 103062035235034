import * as React from 'react';
import Landing from '../components/Landing';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import './../styles.css';

import { useLocation } from '@reach/router';

export default function Index({ data }) {
  const site = data?.site;

  const metaDescription = site.siteMetadata?.description;
  const defaultTitle = site.siteMetadata?.title;

  const lang = 'es';

  const title = site.siteMetadata?.title || 'title';
  const location = useLocation();
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${site.siteMetadata.author}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ].concat()}
        >
          <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
        </Helmet>
      <Landing location={location} />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
